.free-text-block {
    margin: 25px auto;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    @media (max-width: 768px) {
      max-width: 90%;
    }
  }
  
  .free-text-title {
    margin-bottom: 15px;
  }
  
  .columns-container {
    display: flex;
    width: 100%;
    
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  
  .column {
    flex: 1;
    margin: 0 10px;
    width: 30%;
    
    @media (max-width: 768px) {
      margin: 10px 0;
    }
    
    p {
      text-align: left;
    }
  }
  