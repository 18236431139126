.footer-content {
  flex-direction: column;
  align-items: center;
  width: 100%;
  // background: $color-footer;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  padding: 25px;
  padding-left: 100px;
  padding-right: 100px;

  .left-right-sections {
    display: flex;
    justify-content: space-between;
    width: 100%;
     
    
    
    .left-section {
      .ca-privacy-rights {
        /* Existing styles */
        flex-grow: 1;
        // color: $color-background;
        text-align: left;
        margin-right: 0px;
      }
      
      .ca-privacy-rights a {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        // color: $color-background;
      }
      margin: 10px;
      padding: 10px;
      width: 50%;
      text-align: left;
      
      .links-container {
        
      text-align: left;
        justify-content: left;
        align-items: left;
        align-content: left;
        display: flex;
        flex-direction: row;
        line-height: 200%;
      }

    }

    .right-section {
      display: flex;
      justify-content: flex-end;
      margin: 10px;
      padding: 10px;
      width:25%;
      
      .icon a{
        // background: $color-background;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 5px;
      }

}
}



.trademark-info {
  justify-content: left;
  align-items: left;
  text-align: left;
  font-family: 'Arial';
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
  // color: $color-background;
  flex-grow: 0;
  width: 90%;
  padding: 25px;
}


}

@media (max-width: 900px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #443738;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    padding: 0px;
    padding-left: 0px;
    padding-right: 0px;
  
    .left-right-sections {
      display: flex;
      justify-content: stretch;
      width: 100%;
      flex-direction: column;
      
      .left-section {
        width: 100%;

        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
        .links-container {
          justify-content: center;
          display: flex;
          flex-direction: row;
          line-height: 200%;
          
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;

          .ca-privacy-rights {
            /* Existing styles */
            flex-grow: 1;
            // color: $color-background;
            margin-right: 0px;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;
          }
          
          .ca-privacy-rights a {
            font-family: 'Arial';
            font-style: normal;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            // color: $color-background;
          }
        }
  
      }
  
      .right-section {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        width:100%;
        
        .icon a{
          // background: $color-background;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin: 5px;
        }
  
  }
  }
  
  
  
  .trademark-info {
    justify-content: left;
    align-items: left;
    text-align: left;
    font-family: 'Arial';
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
    // color: $color-background;
    flex-grow: 0;
    width: 100%;
    padding: 25px;
  }
  
  }  
}